import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const SoloGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page solo'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/solo-leveling/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/solo/categories/category_intro.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>
            The introduction information about Solo Leveling: Arise and all
            official links.
          </h2>
          <p>
            Last updated: <strong>20/04/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Solo Leveling: ARISE is the first game adaptation of the Solo Leveling
          webtoon. This Action RPG gacha game has been develoled and published
          by Netmarble and while currently it's only available in Canada and
          Thailand, the Global release of the game will happen in May 2024.
        </p>
        <p>
          The game will have an official PC client available on the release,
          which will make playing on your PC much much easier and you will be
          able to fully enjoy the amazing combat!
        </p>
        {/* <p>
          If you want to try the game now, before its Global launch, check this
          guide:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="How to play the game early"
            link="/solo-leveling/guides/how-to-play-early"
            image={
              <StaticImage
                src="../../../images/solo/categories/category_how.jpg"
                alt="How to play the game early"
              />
            }
          />
        </Row> */}
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://sololeveling.netmarble.com/en/pcplay"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Forum:{' '}
            <a
              href="https://forum.netmarble.com/slv_en"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.gg/sololevelingarise-gl"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@SoloLevelingARISE_GL"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Twitter:{' '}
            <a
              href="https://twitter.com/Sololv_ARISE_GL"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.netmarble.sololv&hl=en&gl=US"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/solo-leveling-arise/id1662742277"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Video" />
        <p>If you want to see the gameplay, check the videos below:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="PJpCDY6p-R8" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="94iXUtI2mTI" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default SoloGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Solo Leveling: Arise | Prydwen Institute"
    description="The introduction information about Solo Leveling: Arise and all official links."
    game="solo"
  />
);
